const ADMIN_PRODUCT_SEARCH = '/admin/catalog/material'
const ADMIN_PRODUCT_SEARCH_ELASTIC = '/admin/catalog/material/search'
const ADMIN_MASTERING_MATERIAL_HOME = '/admin/mastering-material/home'
const ADMIN_MASTERING_NEW = '/admin/catalog/mastering/new'
const ADMIN_MASTERING_UPDATE = '/admin/catalog/mastering'
const ADMIN_BULK_RECORDS_EXPORT = '/admin/mastering/bulk-records/export'
const ADMIN_BULK_RECORDS_IMPORT = '/admin/mastering/bulk-records/import'
const ADMIN_BULK_RECORDS_IMPORT_GENERIC = '/admin/mastering/generic'
const ADMIN_BULK_EXISTING_RECORDS_IMPORT = '/admin/mastering/bulk-existing-records/import'
const ADMIN_NEW_RECORD_TEMPLATE_EXPORT = '/admin/mastering/new-record-template/export'
const ADMIN_QUERY_RECORDS = '/admin/mastering/query-records'
const ADMIN_MAP_RECORDS = '/admin/mastering/map-single-record'
const ADMIN_EXPORT_UNMAPPED_RECORDS = '/admin/mastering/export-unmapped-records'

const getMaterialDetailUrl = (materialId) => {
    return `/FieldCatalog/material/${materialId}/detail`
}
const getAdminMaterialDetailUrl = (materialId) => {
    return `/admin/catalog/material/${materialId}`
}

export {
    ADMIN_PRODUCT_SEARCH,
    ADMIN_PRODUCT_SEARCH_ELASTIC,
    ADMIN_MASTERING_UPDATE,
    ADMIN_MASTERING_MATERIAL_HOME,
    ADMIN_MASTERING_NEW,
    ADMIN_BULK_RECORDS_EXPORT,
    getAdminMaterialDetailUrl,
    ADMIN_BULK_RECORDS_IMPORT,
    ADMIN_BULK_RECORDS_IMPORT_GENERIC,
    ADMIN_BULK_EXISTING_RECORDS_IMPORT,
    ADMIN_NEW_RECORD_TEMPLATE_EXPORT,
    ADMIN_QUERY_RECORDS,
    ADMIN_MAP_RECORDS,
    getMaterialDetailUrl,
    ADMIN_EXPORT_UNMAPPED_RECORDS
}
