import React, { useEffect } from "react";
import { Collapse } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import PropTypes from 'prop-types'
import Gap from "../../Gap";
import {
    ADMIN_BULK_RECORDS_EXPORT,
    ADMIN_BULK_RECORDS_IMPORT,
    ADMIN_BULK_EXISTING_RECORDS_IMPORT,
    ADMIN_NEW_RECORD_TEMPLATE_EXPORT,
    ADMIN_MAP_RECORDS,
    ADMIN_MASTERING_MATERIAL_HOME,
    ADMIN_MASTERING_NEW,
    ADMIN_MASTERING_UPDATE,
    ADMIN_PRODUCT_SEARCH,
    ADMIN_QUERY_RECORDS,
    ADMIN_BULK_RECORDS_IMPORT_GENERIC
} from "../../../constants/adminProductSearch/link";
import { SUPPLY_CHAIN_MANAGER_LINK } from "../../../constants/supplyChainManager/link";
import { MATERIAL_IMPORT_LOG } from "../../../constants/materialImportLog/link";
import { ADMIN_EXPORT_UNMAPPED_RECORDS, ADMIN_IMPORT_MAPPED_RECORDS, ADMIN_MAP_SINGLE_RECORDS } from "../../../constants/mappingRecords/link";

export default function AdminSupplyChain(props) {
    const navigate = useNavigate()
    
    const { arrayAdminFeatures, stateMenu, toggleMenuState, isPathActive } = props

    const getAdminSupplyChain = process.env.REACT_APP_ADMIN_SUPPLY_CHAIN;

    // Checking if the supply chain key features is don't exist will get from old key features
    const arrayAdminSupplyChain = getAdminSupplyChain?.split(", ") ?? arrayAdminFeatures;

    // Check active mastering matering is active
    const masteringMaterialPaths = [
        ADMIN_MASTERING_NEW,
        ADMIN_PRODUCT_SEARCH,
        ADMIN_BULK_RECORDS_IMPORT,
        ADMIN_BULK_RECORDS_EXPORT,
        ADMIN_BULK_EXISTING_RECORDS_IMPORT,
        ADMIN_QUERY_RECORDS,
        ADMIN_MASTERING_MATERIAL_HOME,
        ADMIN_MASTERING_UPDATE,
        ADMIN_MAP_RECORDS,
        ADMIN_EXPORT_UNMAPPED_RECORDS,
        ADMIN_IMPORT_MAPPED_RECORDS,
        ADMIN_MAP_SINGLE_RECORDS,
        ADMIN_NEW_RECORD_TEMPLATE_EXPORT,
        SUPPLY_CHAIN_MANAGER_LINK,
        ADMIN_BULK_RECORDS_IMPORT_GENERIC
    ];

    // Check if any mastering material path is active
    const isActiveMasteringMaterial = masteringMaterialPaths?.some(isPathActive);

    //Check supply chain manager is active
    const isActiveSupplyChainManager = isPathActive(SUPPLY_CHAIN_MANAGER_LINK)

    //Check material import log is active
    const isActiveMaterialImportLog = isPathActive(MATERIAL_IMPORT_LOG)

    //Check material import log is active
    const isActiveMaterialGenericRecords = isPathActive(ADMIN_BULK_RECORDS_IMPORT_GENERIC)

    //Check if one of supply chain menu is active
    const isActiveSupplyChain = isActiveMaterialImportLog 
        || isActiveMasteringMaterial || isActiveSupplyChainManager || isActiveMaterialGenericRecords

    // CHecking if menu from new records
    const isActiveNewRecords = isPathActive(ADMIN_BULK_RECORDS_IMPORT_GENERIC) 
        || isPathActive(ADMIN_MASTERING_NEW) || isPathActive(ADMIN_BULK_RECORDS_IMPORT) || isPathActive(ADMIN_NEW_RECORD_TEMPLATE_EXPORT)
    
    // For Keep opening collapsed new records
    useEffect(() => {
        if(!stateMenu.adminSubMenuMasterMaterialNewRecords && isActiveSupplyChain){
            toggleMenuState("adminSubMenuMasterMaterialNewRecords")
        }
       
    }, [isActiveNewRecords])

    return (
        <>
            {arrayAdminSupplyChain?.includes("Material Records") && (
                <li className={
                    isActiveSupplyChain ? "nav-item active" : "nav-item"}>
                    <div
                        className="nav-link"

                        onClick={() => {
                            navigate(ADMIN_PRODUCT_SEARCH)
                            toggleMenuState("adminSubMenuSupplyChain");
                        }}
                    >
                        <i className="ti-view-list menu-icon"></i>
                        <span className="menu-title">Supply Chain</span>
                        <i className="menu-arrow"></i>
                    </div>
                    <Collapse in={stateMenu.adminSubMenuSupplyChain}>
                        <ul className="nav flex-column sub-menu" >
                            <li
                                className="nav-item"
                            >
                                <div
                                    className={
                                        isActiveMasteringMaterial
                                            ? "nav-link active" : "nav-link"
                                    }
                                    onClick={() => {
                                        if (arrayAdminSupplyChain.includes('Material Landing')) {
                                            navigate(ADMIN_MASTERING_MATERIAL_HOME)
                                        } else {
                                            navigate(ADMIN_PRODUCT_SEARCH)
                                        }
                                        toggleMenuState("adminSubMenuMasterMaterialRecords")
                                    }}
                                >
                                    <span className="menu-title">
                                        Master Material <Gap /> Records
                                    </span>
                                    <i className="menu-arrow"></i>
                                </div>
                                <Collapse in={stateMenu.adminSubMenuMasterMaterialRecords}>
                                    <ul className="nav flex-column sub-menu" style={{ marginLeft: '-30px' }}>
                                        {" "}

                            <li
                            //   className={
                            //     location.pathname?.includes("/admin/material")
                            //       ? "nav-item active"
                            //       : "nav-item"
                            //   }
                            className="nav-item"
                            >
                            <Link
                                className={
                                    isPathActive(ADMIN_MASTERING_NEW)
                                        ? "nav-link active"
                                        : "nav-link"
                                }
                                onClick={() => {
                                    navigate(ADMIN_MASTERING_NEW)
                                    toggleMenuState("adminSubMenuMasterMaterialNewRecords")
                                }}
                                to={ADMIN_MASTERING_NEW}
                            >
                                <span className="menu-title">New Records</span>
                                <i className="menu-arrow"></i>
                            </Link>
                            <Collapse in={stateMenu.adminSubMenuMasterMaterialNewRecords}>
                                <ul
                                className="nav flex-column sub-menu"
                                style={{ marginLeft: "-30px" }}
                                >

                                        {arrayAdminSupplyChain?.includes("Material Master"
                                        ) && (
                                            <li className="nav-item">
                                                {" "}
                                                <Link
                                                    className={
                                                        isPathActive(ADMIN_MASTERING_NEW) || isPathActive(ADMIN_MASTERING_UPDATE)
                                                            ? "nav-link active"
                                                            : "nav-link"
                                                    }
                                                    to={ADMIN_MASTERING_NEW}
                                                >
                                                    <span>Create Single <Gap/> Record</span>
                                                </Link>
                                            </li>                            
                                        )}

                                        {arrayAdminSupplyChain?.includes(
                                            "Bulk Import Generic"
                                            ) && (
                                                <li className="nav-item">
                                                {" "}
                                                <Link
                                                    className={
                                                        isPathActive(ADMIN_BULK_RECORDS_IMPORT_GENERIC)
                                                            ? "nav-link active"
                                                            : "nav-link"
                                                    }
                                                    to={ADMIN_BULK_RECORDS_IMPORT_GENERIC}
                                                >
                                                    <span>Import Generic <Gap/> Records </span>
                                                </Link>
                                            </li>                                           
                                        )}
                                        {arrayAdminSupplyChain?.includes(
                                            "Bulk Mastering Import"
                                            ) && (
                                                <li className="nav-item">
                                                {" "}
                                                <Link
                                                    className={
                                                        isPathActive(ADMIN_BULK_RECORDS_IMPORT)
                                                            ? "nav-link active"
                                                            : "nav-link"
                                                    }
                                                    to={ADMIN_BULK_RECORDS_IMPORT}
                                                >
                                                    <span>Import Mastered <Gap/> Records </span>
                                                </Link>
                                            </li>                                           
                                        )}

                                        {arrayAdminSupplyChain?.includes("Bulk Mastering Export") && (
                                        <li className="nav-item">
                                            {" "}
                                            <Link
                                                className={
                                                    isPathActive(ADMIN_NEW_RECORD_TEMPLATE_EXPORT)
                                                        ? "nav-link active"
                                                        : "nav-link"
                                                }
                                                to={ADMIN_NEW_RECORD_TEMPLATE_EXPORT}
                                            >
                                                <span>New Record <Gap /> Template </span>
                                            </Link>
                                        </li>                            
                                        )}
                
                                    </ul>
                                </Collapse>
                            </li>

                            <li
                            className="nav-item"
                            >
                            <Link
                                className={
                                    isPathActive(ADMIN_BULK_EXISTING_RECORDS_IMPORT) 
                                        ? "nav-link active"
                                        : "nav-link"
                                }
                                onClick={() => {
                                    navigate(ADMIN_BULK_EXISTING_RECORDS_IMPORT)
                                    toggleMenuState("adminSubMenuMasterMaterialExistingRecords")
                                }}
                                to={ADMIN_BULK_EXISTING_RECORDS_IMPORT}
                            >
                                <span className="menu-title">Existing Records</span>
                                <i className="menu-arrow"></i>
                            </Link>
                            <Collapse in={stateMenu.adminSubMenuMasterMaterialExistingRecords}>
                                <ul
                                className="nav flex-column sub-menu"
                                style={{ marginLeft: "-30px" }}
                                >

                                    {arrayAdminSupplyChain?.includes(
                                    "Bulk Mastering Import"
                                    ) && (
                                        <li className="nav-item">
                                        {" "}
                                        <Link
                                            className={
                                                isPathActive(ADMIN_BULK_EXISTING_RECORDS_IMPORT)
                                                    ? "nav-link active"
                                                    : "nav-link"
                                            }
                                            to={ADMIN_BULK_EXISTING_RECORDS_IMPORT}
                                        >
                                            <span>Import Updated <Gap /> Records </span>
                                        </Link>
                                        </li>         
                                    )}                                 
            
                                </ul>
                            </Collapse>
                            </li>

                            <li className="nav-item">
                            <Link
                                className={
                                    isPathActive(ADMIN_IMPORT_MAPPED_RECORDS) 
                                        ? "nav-link active"
                                        : "nav-link"
                                }
                                onClick={() => {
                                    navigate(ADMIN_IMPORT_MAPPED_RECORDS)
                                    toggleMenuState("adminSubMenuMasterMaterialMapRecords")
                                }}
                                to={ADMIN_IMPORT_MAPPED_RECORDS}
                            >
                                <span className="menu-title">Map Records</span>
                                <i className="menu-arrow"></i>
                            </Link>
                            <Collapse in={stateMenu.adminSubMenuMasterMaterialMapRecords}>
                                <ul
                                className="nav flex-column sub-menu"
                                style={{ marginLeft: "-30px" }}
                                >

                            {arrayAdminSupplyChain?.includes("Import Mapped Records") && (
                                <li className="nav-item">
                                    {" "}
                                <Link
                                    className={
                                        isPathActive(ADMIN_IMPORT_MAPPED_RECORDS)
                                            ? "nav-link active"
                                            : "nav-link"
                                    }
                                    to={ADMIN_IMPORT_MAPPED_RECORDS}
                                >
                                    <span>Bulk Mapping</span>           
                                </Link>                                 
                                </li>                            
                            )}

                            {/* {arrayAdminSupplyChain?.includes("Map Records") && (
                                <li className="nav-item">
                                    {" "}
                                    <Link
                                        className={
                                            isPathActive(ADMIN_MAP_SINGLE_RECORDS)
                                                ? "nav-link active"
                                                : "nav-link"
                                        }
                                        to={ADMIN_MAP_SINGLE_RECORDS}
                                    >
                                        <span>Single Mapping</span>
                                    </Link>
                                </li>                             
                                )}     

                                {arrayAdminSupplyChain?.includes("Export Unmapped Records") && (
                                    <li className="nav-item">
                                    {" "}
                                    <Link
                                        className={
                                            isPathActive(ADMIN_EXPORT_UNMAPPED_RECORDS)
                                                ? "nav-link active"
                                                : "nav-link"
                                        }
                                        to={ADMIN_EXPORT_UNMAPPED_RECORDS}
                                    >
                                        <span>Unmap Record</span>
                                    </Link>                   
                                    </li>                
                                )}                          */}
            
                                </ul>
                            </Collapse>
                            </li>

                            <li className="nav-item">
                            {" "}
                            <Link
                                className={
                                    isPathActive(ADMIN_PRODUCT_SEARCH)
                                        ? "nav-link active"
                                        : "nav-link"
                                }
                                to={ADMIN_PRODUCT_SEARCH}
                            >
                                <span>
                                    Search & Update  <Gap /> Records
                                </span>
                            </Link>                                                                
                            </li>
                                    </ul>
                                </Collapse>
                            </li>

                            {arrayAdminSupplyChain?.includes("Supply Chain Admin" ) && (
                            <li className="nav-item">
                                <Link
                                    className={
                                        isPathActive(
                                            SUPPLY_CHAIN_MANAGER_LINK
                                        )
                                            ? "nav-link active"
                                            : "nav-link"
                                    }
                                    to={SUPPLY_CHAIN_MANAGER_LINK}
                                >
                                    <span>Metadata Manager</span>
                                </Link>
                            </li>                           
                            )}

                            <li className="nav-item">
                                {" "}
                                <Link
                                    className={
                                        isPathActive(
                                            "/admin/material-import-log"
                                        )
                                            ? "nav-link active"
                                            : "nav-link"
                                    }
                                    to="/admin/material-import-log"
                                >
                                    <span> Master Record <Gap /> Import Log</span>
                                </Link>
                            </li>
                            {arrayAdminSupplyChain?.includes(
                                "Material Data"
                            ) && (
                                    <li className="nav-item">
                                        <Link
                                            className={
                                                isPathActive(
                                                    "/admin/smart-records"
                                                )
                                                    ? "nav-link active"
                                                    : "nav-link"
                                            }
                                            to="/admin/smart-records"
                                        >
                                            Material Data
                                        </Link>
                                    </li>
                                )}


                        </ul>
                    </Collapse>
                </li>
            )}
        </>
    )
}

AdminSupplyChain.propTypes = {
    userData: PropTypes.any,
    stateMenu: PropTypes.any.isRequired,
    toggleMenuState: PropTypes.func.isRequired,
    isPathActive: PropTypes.func.isRequired
}

